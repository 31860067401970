import React, { useState, useEffect, useContext } from 'react';
import Layout from "../../components/layout";
import QuestionCard from "../../components/questionCard"
import FormTable from "../../components/formTable";
import Definition from "../../components/definition";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import Li from "../../components/li";
import {Typography, Box} from "@material-ui/core";
import { QuestionnaireContext } from "../../context/questionnaireContext";
import theme from '../../gatsby-theme-material-ui-top-layout/theme';
import { graphql } from 'gatsby';


export default function Part4() {
  const [answer, setAnswer] = useState(null);
  const [nextDisabled, setNextDisable] = useState(true);
  let previousEntry =  "";
  const {t} = useTranslation();

  if (typeof window !== 'undefined') {
    previousEntry = localStorage.getItem("screening4") || "";
  }

  const columns = [
    `${t("part1Col1")} *`,
    `${t("Non-invasive prenatal testing (NIPT)")}***`
  ]

  const rows = [
    [
      t("First blood test: between the 10th and 13th week of pregnancy. Second blood test: between the 14th and 16th week of pregnancy."),
      t("From the 10th week of pregnancy.")
    ],
    [
      t("Approximately 85% of cases, when both samples are taken."),
      t("More than 99% of cases.")
    ],
    [
      t("Indicates a false result in about 3% of cases."),
      t("Indicates a false result in less than 1% of cases.")
    ],
    [
      t("About 10 days after the second blood test."),
      t("About 5 to 10 days.")
    ],
    [
      t("No failure."),
      t("Failure in about 4% of cases.")
    ]
  ]

  const choices = [
    {value:"TBI", label:<Definition word="tbi">{`${t("part1Col1")} *`}</Definition>},
    {value:"TGPNI", label:<Definition word="tgpni">{t("Non-invasive prenatal testing (NIPT)")}</Definition>, disabled: previousEntry === "In PDPQ"}
  ]

  const optionsColumn = [
    '', 
    t("Time period in which the test is performed"), 
    <Definition word="detection">{t("Detection rate")}</Definition>,
    <Definition word="false">{t("False positive rate")}</Definition>,  
    t("Waiting time for the result"),
    t("Failure rate"), 
    ]


  const handleChange = (event) => {
    setAnswer(event.target.value)
  }

  useEffect(() => {
    if(answer !== null)
      setNextDisable(false)
      localStorage.setItem("screening4", answer)
  }, [answer]);

  useEffect(() => {
    if(previousEntry !== "") {
      setAnswer(previousEntry)
    }
  }, []);

  return ( 
    <Layout pageTitle={t("Which screening option is right for me?")} maxWidth={false} next="/screening/part5" nextDisabled={nextDisabled}>
      <Box marginTop={theme.spacing(0.5)}>
        <Typography variant="body1" gutterBottom={true}>
            <strong>
              {t("Here are some factors to consider that may influence your choice of screening test.")}
            </strong>
            <br/>
            <br/>
            {t("Think about what is most important to you.")}
            <br/>
          </Typography>
      </Box>
      <FormTable custom={false} rows={rows} columns={columns} optionsColumn={optionsColumn} fixed={true} part="part3" columnDisabled={previousEntry === "In PDPQ" ? 2 : null}/>
      <QuestionCard question={t("What is your decision?")} choices={choices} answer={answer} handleChange={handleChange} />
      <Typography>
          {'* '}
          {t("If nuchal region")}
      </Typography>
      <Typography>
          {'** '}
          {t("Test at the right time")}
      </Typography>
      <Typography>
          {'*** '}
          {t("The NIPT is offered without fees by the Quebec Program as a first screening test in the following situations only:")}
        </Typography>
        <ol>
          <Li>{t("If, in a previous pregnancy, the baby had trisomy 21, trisomy 18, or trisomy 13.")}</Li>
          <Li>{t("If you are 40 years of age or older at the time of delivery.")}</Li>
          <Li>{t("If you have obtained a prescription following a consultation in genetic medicine.")}</Li>
          <Li>{t("If you are pregnant")}</Li>
        </ol>
        <Typography>
          {t("If you do not meet these conditions, and you want to do this test first, you will have to go to a private clinic (fee may apply).")}
        </Typography>
    </Layout>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
