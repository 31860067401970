import {
  Box,
  FormControlLabel,
  Paper,
  IconButton,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core/";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useEffect, useReducer, useState } from 'react';
import AccentFrame from "./accentFrame";
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root:{
    "& td":{
      padding: "10px",
    },
    "& th": {
      height:"50px",
      padding: "5px 5px 5px 15px",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      fontWeight:"bold",
      textAlign:"center",
      fontSize:"16px"
    },
  },
  table: {
    width: "100%",
    tableLayout:"fixed",
    minWidth: 800,
  },
  threeCol: {
    '&:nth-of-type(even)': {
      width: "18%",
    }
  },
  optionsColumn: {
    width: "13%",
    fontWeight: "bold"
  }
}))

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ResultText = (props) => {
  const {answers, columns} = props;
  const result = {}
  const {t} = useTranslation()
  Object.entries(answers).forEach(([_,v]) => {
    result[v] = (result[v] || 0) + 1;
  })

  const columnValue = () => {
    const colValue = {}
    for (const [i, col] of columns.entries()){
      colValue[`q${i}`] = col
    }
    return colValue;
  }

  return (
    <AccentFrame>
      <Typography variant="body1"><strong>{t("Your choices:")}</strong></Typography>
      <ul> 
        <Typography variant="body1">
            {Object.keys(result).map((k)=>(
              <li key={k}>{`${result[k]} ${t("for")} "${columnValue()[k]}"`}</li>
            ))}
        </Typography>
      </ul>
    </AccentFrame>

  )
}

export default function FormTable(props) {
  const classes = useStyles()
  const [newRows, setNewRows] = useState([])
  const [answers, setAnswers] = useReducer((state, newState) => ({...state, ...newState}), () => defaultState);
  const [leftText, setLeftText] = useState("");
  const [rightText, setRightText] = useState("");
  const {
    columns,
    rows,
    part,
    custom = true,
    columnDisabled = null,
    fixed = false,
    optionsColumn = null
  } = props;
  const {t} = useTranslation();

  useEffect(()=>{
    setAnswers(JSON.parse(localStorage.getItem(`formTable${part}`)) || defaultState)
    if(newRows.length > 0 ) {
      rows.push(...newRows)
    } 
  }, [])

  useEffect(()=>{
    localStorage.setItem(`formTable${part}`, JSON.stringify(answers));
  }, [answers])

  const handleNewRow = (() => {
    const newRow = [
      leftText,
      t("Equal importance"),
      rightText
    ]

    setNewRows([...newRows, newRow])

    setLeftText("")
    setRightText("")
  })

  const handleLeftChange = ((event) => {
    setLeftText(event.target.value)
  })

  const handleRightChange = ((event) => {
    setRightText(event.target.value)
  })

  const defaultState = () => {
    const questionState = {}
    for (let i = 0; i < rows.length; i++) {
      questionState[`question${i}`] = ""
    }
    return questionState;
  }

  const handleChange = (question) => (event) => {
    setAnswers({[question]: event.target.value})
  }

  return (
    <div>
      <TableContainer component={Paper} className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {optionsColumn !== null
              ? <StyledTableCell className={classes.optionsColumn} >{optionsColumn[0]}</StyledTableCell>
              : null
              }
              {columns.map((col, i) => (
                <StyledTableCell className={fixed ?  null : classes.threeCol} key={i} >{col}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i)=>{
              return(
              <StyledTableRow key={i}>
                {
                optionsColumn
                  ? <StyledTableCell className={classes.optionsColumn}>{optionsColumn[i + 1]}</StyledTableCell>
                  : null
                }
                {row.map((cell, j) => (
                  <StyledTableCell key={j}>
                    <FormControlLabel label={cell} control= { 
                      <Radio name={`question${i}`} 
                             checked={answers[`question${i}`] === `q${j}`}
                             disabled={columnDisabled === j}
                             onChange={handleChange(`question${i}`)}
                             value={`q${j}`}/>} />
                  </StyledTableCell>
                ))}
              </StyledTableRow>
              )})}
              {newRows.map((row, i)=>{
              return(
              <StyledTableRow key={rows.length + i}>
                {
                optionsColumn
                  ? <StyledTableCell className={classes.optionsColumn}>{optionsColumn[i + 1]}</StyledTableCell>
                  : null
                }
                {row.map((cell, j) => (
                  <StyledTableCell key={rows.length + j}>
                    <FormControlLabel label={cell} control= { 
                      <Radio 
                        name={`question${rows.length + i}`} 
                        checked={answers[`question${rows.length + i}`] === `q${j}`}
                        disabled={columnDisabled === j}
                        onChange={handleChange(`question${rows.length + i}`)}
                        value={`q${j}`}
                      />
                    }/>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
              )})}
              {custom && 
                <StyledTableRow>
                    <StyledTableCell>
                      <TextField fullWidth={true} onChange={handleLeftChange} value={leftText}/>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography>
                      {t("Equal importance")}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box display="flex">
                        <TextField fullWidth onChange={handleRightChange} value={rightText}/>
                        <IconButton onClick={handleNewRow}>
                          <AddIcon/>
                        </IconButton>
                      </Box>
                    </StyledTableCell>
                </StyledTableRow>
              }
          </TableBody>
        </Table>
      </TableContainer>
      {
        Object.keys(answers).length === 0 
        ? null
        : <ResultText answers={answers} columns={columns}></ResultText>
      }
    </div>
  );
}

